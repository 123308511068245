<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Eventos</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center" v-if="item">
          {{ formTitle }}
        </h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <b-row cols="12">
        <b-col lg="4">
          <b-card no-body>
            <!-- metting header -->

            <b-card-title class="mb-25">
              <b-button variant="primary" block to="/eventos">
                Eventos</b-button
              >
            </b-card-title>
          </b-card>

          <b-list-group>
            <template v-for="item in itemsmenu">
              <b-list-group-item :key="item.entrada.id">
                <router-link
                  :to="'/evento/' + item.entrada.url"
                  style="text-decoration: none"
                  exact-path
                  >{{ item.entrada.titulo }}</router-link
                >
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-col>

        <b-col lg="8">
          <!-- <div class="mt-2">
            <b-input-group>
              <b-form-input
                @keyup.enter="buscar"
                placeholder="Busqueda por fiscalias"
                v-model="textBusqueda"
              />
              <b-input-group-append>
                <b-button class="btn-fiscalia" @click="buscar">
                  Buscar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-row class="mt-2" v-for="item in itemsBusqueda" :key="item.id">
            <b-col>
              <FiscaliaInfo :fiscaliaData="item" />
            </b-col>
          </b-row> -->
          <div class="" v-if="item.entrada">
            <div v-if="item.entrada.imagen" class="img-portada lg-round">
              <b-img-lazy :src="item.entrada.imagen" alt="" fluid-grow>
              </b-img-lazy>
            </div>
            <div class="mt-4">
              <h3 class="titulo" data-aos="fade-up">
                {{ item.entrada.titulo }}
              </h3>
              <b-media no-body>
                <b-media-aside class="me-1">
                  <b-avatar rounded variant="primary" size="40">
                    <feather-icon icon="CalendarIcon" size="20" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ item.fecha }}
                  </h6>
                  <small v-if="item.hora_inicio">
                    {{ item.hora_inicio }} a {{ item.hora_final }}</small
                  >
                  <small v-else> Todo el Dia </small>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="me-1">
                  <b-avatar rounded variant="primary" size="40">
                    <feather-icon icon="MapPinIcon" size="20" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ item.ciudad }}
                  </h6>

                  <small> {{ item.nombre_lugar }}, {{ item.direccion }} </small>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="me-1">
                  <b-avatar rounded variant="primary" size="40">
                    <feather-icon icon="PhoneIcon" size="20" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ item.telefono }}
                  </h6>
                  <span>&nbsp;</span>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="me-1">
                  <b-avatar rounded variant="primary" size="40">
                    <feather-icon icon="UsersIcon" size="20" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ item.nombre_organizador }}
                  </h6>
                  <small>
                    {{ item.telefono_organizador }}
                  </small>
                </b-media-body>
              </b-media>

              <div class="mt-2" data-aos="fade-up">
                <div v-html="item.entrada.descripcion"></div>
                <div v-html="item.entrada.contenido"></div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <l-map
              :zoom.sync="zoom"
              :options="mapOptions"
              :center="center"
              style="height: 500px; width: 100%"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <v-marker-cluster
                :options="clusterOptions"
                @clusterclick="click()"
                @ready="ready"
              >
                <l-marker
                  v-for="l in locations"
                  :key="l.id"
                  :lat-lng="l.latlng"
                  :icon="l.icon"
                >
                  <l-popup :content="l.text"></l-popup>
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </div>
        </b-col>
      </b-row>
      <div id="navegacion" class="mt-5 mb-5 row"></div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";

import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import TituloSeccion from "../secciones/TituloSeccion.vue";

import { latLng, Icon, icon } from "leaflet";
import "leaflet/dist/leaflet.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    TituloSeccion,

    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      textBusqueda: "",
      locations: [],
      item: {
        entrada:{
          titulo:"",
        }
      },
      itemp: "",
      itemsmenu: [],
      itemsBusqueda: [],
      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: false,
      },
      selected: "0",
      zoom: 12,
      center: [-19.062117883514652, -65.25878906250001],
      clusterOptions: {},
      icon: icon({
        iconUrl: require("../../assets/images/leaflet/fiscalia-puntero.svg"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      iconActivo: icon({
        iconUrl: require("../../assets/images/leaflet/fiscalia-puntero-activo.svg"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      preview: "",
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      if (this.$route.query.preview) {
        this.preview = this.$route.query.preview;
      }
      this.obtenerEvento(this.$route.params.slug);
      this.ultimoseventos();
      setTimeout(() => {
        console.log("done");
        this.$nextTick(() => {
          this.clusterOptions = { disableClusteringAtZoom: 11 };
        });
      }, 5000);
    }
  },
  updated() {},
  computed: {
    formTitle() {
      return this.item.nombre
        ? this.item.entrada.titulo
        : this.item.entrada.titulo;
    },
  },
  created() {},
  watch: {
    $route: function (val) {
      if (val.params.slug) {
        this.obtenerEvento(this.$route.params.slug);
        this.ultimoseventos();
      }
    },
  },
  metaInfo() {
    return {
      title: "Ministerio Público",
      htmlAttrs: {
        lang: "es-ES",
      },
      titleTemplate: "%s | " + this.item.entrada.titulo,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.item.entrada.titulo,
        },
      ],
    };
  },

  methods: {
    ultimoseventos() {
      axiosIns
        .get(`/front/ultimoseventos`)
        .then((res) => {
          this.itemsmenu = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    buscar() {
      if (this.textBusqueda != "") {
        const variable = `?busqueda=${this.textBusqueda}`;
        console.warn(variable);
        axiosIns
          .get(`/front/fiscalias${variable}`)
          .then((res) => {
            this.itemsBusqueda = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.itemsBusqueda = [];
      }
    },
    obtenerEvento(slug) {
      //var url = "/front/fiscalias";
      this.itemsmenu = [];
      this.locations = [];
      var url = "/front/eventos/" + slug + "?preview=" + this.preview;
      axiosIns
        .get(url)
        .then((res) => {
          if (res.data.data) {
            this.item = res.data.data;
            this.agregarPosicion(this.item, this.iconActivo);
            this.center = [this.item.latitud, this.item.longitud];
          } else {
            this.$router.push({ name: "error-404" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    agregarPosiciones(positionArray) {
      positionArray.forEach((element) => {
        this.agregarPosicion(element, this.icon);
      });
    },
    agregarPosicion(element, icon) {
      this.locations.push({
        id: element.id,
        latlng: latLng(element.latitud, element.longitud),
        icon: icon,
        text: `<div>                                      
                <table class="cuadro_info_dentro_mapa">
                    <thead>
                        <tr >
                            <th colspan="2" class="text-center">
                            <h5 class="text-black">${element.entrada.titulo}</h5>
                            </th>
                        </tr>
                    </thead>
                    
                    <tbody>                                            
                    <tr>
                        <th scope="row">Lugar</th>
                        <td>${element.nombre_lugar}</td>
                    </tr>
                    <tr>
                        <th scope="row">Dirección</th>
                        <td>${element.direccion}</td>
                    </tr>
                    <tr>
                        <th scope="row">Teléfono</th>
                        <td>${element.telefono}</td>
                    </tr>
                    <tr>
                        <th scope="row">Como LLegar</th>
                        <td><a href="https://www.google.com/maps/dir/${element.latitud},${element.longitud}" target="_blank" rel="noopener noreferrer" class="btn small">Como llegar</a></td>
                    </tr>
                    </tbody>
                </table>
                </div>`,
      });
    },
  },
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>